/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function isOlderThan(minAge: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }

    const compareDate = new Date();
    compareDate.setFullYear(compareDate.getFullYear() - minAge);
    const valueDate = new Date(value);

    return compareDate.getTime() > valueDate.getTime() ? null : { younger: true };
  };
}

export function isValidYear(earliestYear: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }

    const compareDate = new Date();
    compareDate.setFullYear(earliestYear);
    const valueDate = new Date(value);

    return valueDate.getFullYear() > compareDate.getFullYear() ? null : { valid: true };
  };
}
