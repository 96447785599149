<div class="top-of-form"></div>
<merchant-app-card
  [stepNumber]="!forConsole ? this.stepNumber + 1 : null"
  [title]="!forConsole ? 'Business Details' : null"
  [description]="!forConsole ? 'Tell us about your business' : null"
  [backButton]="false"
  (continueClick)="onContinueClicked($event)"
  [continueButton]="!forConsole && !(disabled$ | async)"
>
  <form data-test-id="details-step" class="w-full" [formGroup]="businessDetailsForm">
    <tilled-input
      data-test-id="details-legal-name"
      class="w-full"
      name="legalName"
      placeholder="Full Legal Business Name"
      label="LEGAL BUSINESS NAME"
      tilledLabel="true"
    ></tilled-input>
    <tilled-input
      data-test-id="details-dba"
      class="w-full"
      name="dba"
      placeholder="Business “DBA” or Trading Name"
      label="DOING BUSINESS AS"
      tilledLabel="true"
      toolTip="The business name you present to customers"
    ></tilled-input>
    <div class="col-span-1 flex w-full flex-col">
      <div class="w-full pb-2.5 pt-4">
        <tilled-label-l1 class="w-full" secondary="true">Business entity type</tilled-label-l1>
      </div>
      <tilled-select
        classOverride="w-full"
        data-test-id="details-entity-type"
        [multiple]="false"
        [options]="entityTypesOptions"
        [controlName]="businessDetailsForm.get('type')"
        [placeholder]="'Choose One'"
        [errors]="businessDetailsForm.get('type').hasError('required') && businessDetailsForm.get('type').touched"
        errorMessage="A business entity type is required"
        toolTip="Your legal business structure as registered for tax filing"
      ></tilled-select>
    </div>
    <tilled-input
      data-test-id="details-business-identifier"
      class="w-full"
      name="businessIdentifier"
      placeholder="Tax ID #, Canadian Business #, Social Security # or EIN"
      label="BUSINESS IDENTIFIER"
      tilledLabel="true"
      mask="000000000"
      toolTip="ie: Tax ID#, Canadian Business #, SSN # or EIN"
      [errors]="
        businessDetailsForm.get('businessIdentifier').invalid && businessDetailsForm.get('businessIdentifier').touched
      "
      errorMessage="Business Identifier must be 9 digits."
    ></tilled-input>

    <tilled-input
      data-test-id="details-date-of-inc"
      name="dateOfInc"
      label="Date of incorporation"
      placeholder="MM/DD/YYYY"
      [sentenceCasePlaceholder]="false"
      tilledLabel="true"
      mask="M0/d0/0000"
      [dropMaskCharacters]="false"
      [leadZeroDateTime]="true"
      [errors]="businessDetailsForm.get('dateOfInc').errors?.['mask']"
      errorMessage="Date of incorporation must be a valid date."
    ></tilled-input>

    <div class="col-span-1 flex flex-col">
      <div class="w-full pb-2.5 pt-4">
        <tilled-label-l1 secondary="true">Industry</tilled-label-l1>
      </div>
      <tilled-select
        *ngIf="mccsLoaded$ | async"
        classOverride="w-full"
        data-test-id="details-business-category"
        [multiple]="false"
        [options]="mccCodeMap"
        [controlName]="businessDetailsForm.get('category')"
        [placeholder]="'Choose One'"
        [errors]="
          businessDetailsForm.get('category').hasError('required') && businessDetailsForm.get('category').touched
        "
        errorMessage="A business category is required"
      ></tilled-select>
    </div>
    <tilled-input
      data-test-id="details-b2b-percent"
      name="b2bPercent"
      label="What percent of your company's sales are made directly to other businesses? Estimates are okay."
      tilledLabel="true"
      placeholder="Enter %"
      [showPercent]="true"
      [sentenceCaseTitle]="false"
      minValue="0"
      maxValue="100"
      mask="percent"
      [errors]="
        businessDetailsForm.get('b2bPercent').errors?.['min'] ||
        (businessDetailsForm.get('b2bPercent').errors?.['max'] && businessDetailsForm.get('b2bPercent').touched)
      "
      [errorMessage]="'Must not be greater than 100%'"
    ></tilled-input>
    <tilled-input
      data-test-id="details-statement-descriptor"
      class="w-full pb-2.5"
      name="statementDescriptor"
      placeholder="Statement Descriptor"
      label="What name do you want to appear on customer credit card statements?"
      maxLength="20"
      tilledLabel="true"
    ></tilled-input>
    <div>
      <div class="w-full pb-2.5 pt-4">
        <tilled-label-l1 secondary="true">Describe the product and services you offer</tilled-label-l1>
      </div>
      <mat-form-field appearance="fill" class="w-full">
        <textarea
          class="text-field"
          data-test-id="details-description"
          [formControlName]="'description'"
          matInput
          maxLength="240"
          cdkTextareaAutosize
          cdkAutosizeMinRows="3"
          cdkAutosizeMaxRows="5"
          placeholder="Describe product and services you offer in 240 characters or less"
        ></textarea>
      </mat-form-field>
    </div>
  </form>
</merchant-app-card>
