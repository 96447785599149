<div class="top-of-form"></div>
<merchant-app-card
  [stepNumber]="!forConsole ? this.stepNumber + 1 : null"
  [title]="!forConsole ? 'Business Contact Information' : null"
  [description]="
    !forConsole
      ? 'This section should include the contact details you share with your customers to get in touch with your business'
      : null
  "
  [backButton]="!forConsole"
  (backClick)="onBackClicked($event)"
  (continueClick)="onContinueClicked($event)"
  [continueButton]="!forConsole && !(disabled$ | async)"
>
  <form data-test-id="contact-step" class="w-full" [formGroup]="businessContactForm">
    <tilled-input
      data-test-id="contact-phone-number"
      class="w-full"
      name="phone"
      [prefix]="businessContactForm.get('phone')?.value?.includes('+') ? '' : selectedPhoneCode"
      placeholder="(555) 000-0000"
      [mask]="businessContactForm.get('phone')?.value?.includes('+') ? '+0 (000) 000-0000' : '(000) 000-0000'"
      label="Customer support phone number"
      tilledLabel="true"
      [errors]="businessContactForm.get('phone').hasError('invalidPhone') && businessContactForm.get('phone').touched"
      errorMessage="Must be a valid phone number."
      [prefixMultiple]="false"
      [prefixOptions]="phoneCodeMap"
      [prefixControlName]="businessContactForm.get('phoneCode')"
      [prefixPlaceholder]="'US'"
    ></tilled-input>
    <tilled-input
      data-test-id="contact-email"
      class="w-full"
      name="email"
      placeholder="support@company.com"
      [sentenceCasePlaceholder]="false"
      label="Customer support email"
      tilledLabel="true"
      [errors]="businessContactForm.get('email').hasError('invalidEmail') && businessContactForm.get('email').touched"
      errorMessage="Email Address must be valid."
    ></tilled-input>

    <tilled-input
      data-test-id="contact-website"
      class="w-full"
      name="website"
      [prefix]="
        businessContactForm.get('website').value !== ('' || null) &&
        businessContactForm.get('website')?.value?.includes('https://')
          ? ''
          : 'https://'
      "
      prefixSuffixBorder="prefix"
      placeholder="www.yourcompanyname.xyz"
      [sentenceCasePlaceholder]="false"
      label="Company website"
      tilledLabel="true"
      [errors]="businessContactForm.get('website').errors && businessContactForm.get('website').touched"
      errorMessage="Website must be a valid URL. `https://` is not required."
    ></tilled-input>
    <div class="col-span-1 mb-2 flex w-full flex-col">
      <div class="w-full pb-2.5 pt-4">
        <tilled-label-l1 class="w-full" secondary="true">Legal business address</tilled-label-l1>
      </div>
      <app-autocomplete
        data-test-id="contact-street"
        class="w-full"
        name="street"
        placeholder="123 Main St"
        [autocompleteAddress]="businessContactForm.value.street"
        (setAddress)="setBusinessAddress($event)"
        hint="This should be the address where your business is physically located. P.O. boxes are not accepted."
      ></app-autocomplete>
    </div>
    <tilled-input
      data-test-id="contact-street2"
      class="w-full"
      name="street2"
      placeholder="Suite / Apt / Unit (Optional)"
      label="STREET ADDRESS 2"
      tilledLabel="true"
    ></tilled-input>
    <div class="grid w-full grid-cols-1 gap-x-6 sm:grid-cols-2">
      <div class="col-span-1 flex flex-col">
        <tilled-input
          data-test-id="contact-city"
          name="city"
          placeholder="Louisville"
          label="CITY"
          tilledLabel="true"
        ></tilled-input>
      </div>
      <div class="col-span-1 flex w-full flex-col">
        <div class="w-full pb-2.5 pt-4">
          <tilled-label-l1 secondary="true">State</tilled-label-l1>
        </div>
        <tilled-select
          [multiple]="false"
          [options]="stateCodeMap"
          [placeholder]="'Choose One'"
          [controlName]="businessContactForm.get('state')"
          classOverride="w-full"
          data-test-id="contact-state"
        ></tilled-select>
      </div>
    </div>
    <tilled-input
      data-test-id="contact-zip"
      class="w-1/2"
      name="zip"
      placeholder="80205"
      label="ZIP"
      tilledLabel="true"
      maxLength="10"
    ></tilled-input>
  </form>
</merchant-app-card>
