<form-card
  [titleText]="title"
  [secondaryText]="inviteText"
  [submitButton]="true"
  [submitText]="submitButtonText"
  [exitButton]="true"
  [backButton]="applicantInvite"
  [backButtonText]="'Change primary applicant'"
  (backClick)="closeDialog('changeApplicant')"
  [submitDisabled]="emailExists ? false : userInviteForm.invalid || disabled"
  [displayAlert]="displayAlert$ | async"
  [alertTitle]="'Error inviting collaborator'"
  [alertMessage]="alertMessage"
  [testIds]="{ submitButton: 'share-via-email-button' }"
  data-test-id="invite-form"
  (exitClick)="closeDialog()"
  (submitClick)="emailExists ? closeDialog('continue') : inviteUser('email')"
>
  <div *ngIf="emailExists" class="-mt-4 flex flex-wrap justify-start">
    The primary applicant already has access to this application
    <b>and will need to login to electronically sign the Merchant Agreement on the final step.</b>
  </div>
  <div class="grid w-full grid-cols-1 gap-x-6">
    <div class="col-span-1 flex flex-col">
      <ng-container *ngIf="!emailExists">
        <tilled-input
          data-test-id="invite-email"
          label="EMAIL ADDRESS"
          tilledLabel="true"
          name="email"
          [formGroup]="userInviteForm"
          placeholder="Enter email address"
          [errors]="userInviteForm.get('email').errors && userInviteForm.get('email').touched"
          errorMessage="Email Address must be valid."
          [readonly]="userInviteForm.invalid || disabled"
          [copyToClipboard]="true"
          (copyClick)="inviteUser('copy')"
          [copyClickText]="'Generate'"
        >
        </tilled-input>
        <div
          *ngIf="showLinkToUsers && accountUserCount > 0"
          class="flex cursor-pointer flex-row justify-center pt-6 underline"
          (click)="navigateToUsers()"
        >
          <tilled-paragraph-p3 secondary="true">view previously invited users</tilled-paragraph-p3>
        </div>
      </ng-container>
    </div>
  </div>
</form-card>
