// these are mainly used for navigating from the submit step to the correct step number
export enum MerchantApplicationStepType {
  BUSINESS_DETAILS = 'business_details',
  BUSINESS_CONTACT = 'business_contact',
  PROCESSING_VOLUMES = 'processing_volumes',
  REPRESENTATIVES = 'representatives',
  BANK_ACCOUNT = 'bank_account',
  BUSINESS_DOCUMENTS = 'business_documents',
  SUBMIT = 'submit',
  OTHER = 'other', // placeholder for new steps
}

export enum PaysafeApplicationSteps {
  BUSINESS_DETAILS_STEP = 0,
  BUSINESS_CONTACT_STEP = 1,
  PROCESSING_VOLUMES_STEP = 2,
  REPRESENTATIVES_STEP = 3,
  BANK_ACCOUNT_STEP = 4,
  SUBMIT_STEP = 5,
}

export enum TsysApplicationSteps {
  BUSINESS_DETAILS_STEP = 0,
  BUSINESS_CONTACT_STEP = 1,
  PROCESSING_VOLUMES_STEP = 2,
  REPRESENTATIVES_STEP = 3,
  BANK_ACCOUNT_STEP = 4,
  BUSINESS_DOCUMENTS_STEP = 5,
  SUBMIT_STEP = 6,
}

export const MerchantAppMissingFields = {
  BusinessDetailsStep: {
    LEGAL_NAME: 'Legal Business Name',
    DBA: 'Doing Business As',
    ENTITY_TYPE: 'Business Entity Type',
    BUSINESS_ID: 'Business Identifier',
    INDUSTRY: 'Industry',
    STATEMENT_DESCRIPTOR: 'Statement Descriptor',
    PRODUCT_DESCRIPTION: 'Product and Services Description',
    DATE_OF_INC: 'Date of Incorporation',
    PERCENT_B2B: 'Percent Business-to-business',
  },
  BusinessDocumentsStep: {
    BUSINESS_DOCS: 'Business Documents',
    AOI_DOCUMENT: 'Articles of Incorporation Document Upload',
    AOI_ISSUED_AT: 'Articles of Incorporation Issued Date',
    AOI_STATE: 'Articles of Incorporation State',
    BL_DOCUMENT: 'Business License Document Upload',
    BL_EXPIRES_AT: 'Business License Expiry Date',
    BL_ISSUED_AT: 'Business License Issued Date',
    BL_STATE: 'Business License State',
    BL_NAME: 'Business License Name',
  },
  ContactInfoStep: {
    PHONE: 'Customer Support Phone Number',
    EMAIL: 'Customer Support Email',
    WEBSITE: 'Company Website',
    STREET: 'Legal Business Street Address',
    CITY: 'City',
    STATE: 'State',
    POSTAL: 'Postal Code',
  },
  VolumesStep: {
    MONTHLY_VOLUME: 'Monthly Processing Volume',
    AVERAGE_AMOUNT_CARD: 'Credit Card - Average Transaction Amount',
    AVERAGE_AMOUNT_DEBIT: 'Bank to Bank - Average Transaction Amount',
    AVERAGE_TRX_PER_MONTH: 'Number of Transactions Per Month',
    TRX_PERCENTS: 'Checkout Method Percentages',
  },
  RepresentativesStep: {
    NO_PRINCIPALS: 'Need at least one Business Representative',
    FIRST_NAME: 'First Name',
    LAST_NAME: 'Last Name',
    SSN: 'Social Security Number',
    PHONE: 'Phone Number',
    DOB: 'Date of Birth',
    JOB_TITLE: 'Job Title',
    OWNERSHIP: 'Ownership Percent',
    STREET: 'Street Address',
    CITY: 'City',
    STATE: 'State',
    POSTAL: 'Postal Code',
    PREV_STREET: 'Previous Street Address',
    PREV_CITY: 'Previous City',
    PREV_STATE: 'Previous State',
    PREV_POSTAL: 'Previous Postal Code',
    APPLICANT: 'One Business Representative must be marked as Applicant',
    CONTROL_PRONG: 'One Business Representative must be marked as Control Prong',
    SIGNATORY: 'One Business Representative must be marked as Signatory',
    EMAIL: 'Email Address',
  },
  BankAccountStep: {
    ACCOUNT_NUMBER: 'Account Number',
    ROUTING_NUMBER: 'Routing Number',
    ROUTING_NUMBER_CA: 'Transit Number and Institution ID',
    TYPE: 'Account Type',
    ACCOUNT_HOLDER_NAME: 'Account Holder or Business Name',
    BANK_NAME: 'Bank Name',
    DOC_UPLOAD: 'Upload a Bank Letter or Voided Check',
  },
};
